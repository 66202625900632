import React, { useReducer, useRef, useEffect, useState } from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import Media from "react-media";

import Scrollbar from "../../containers/Scrollbar";

import {
  DropDown,
  buttonSubmit,
  SubmitButton,
  LabelBox,
  FormWrap,
  FormContent,
  cfTitle,
  formField,
  formFieldBorder,
  FormLabel,
  FormLabelName,
  formElement,
  formInput,
  formSelect,
  checkboxLabel,
  formTextarea,
  ErrorField,
  messageSent,
  messageSentContainer,
  lastField,
} from "./Components";

const ConditionalScrollbar = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const initialState = {
  department: "",
  name: "",
  email: "",
  message: "",
  acceptance: false,
  recaptcha: false,
};

const initialErrors = {
  department: "",
  name: "",
  email: "",
  message: "",
  acceptance: "",
  recaptcha: "",
};

const errors = Object.keys(initialState).reduce((prev, curr) => {
  prev[curr] = "";
  return prev;
}, {});

function reducer(state, { field, value, type }) {
  switch (type) {
    case "update":
      return {
        ...state,
        [field]: value,
      };
    case "reset":
    default:
      return {
        ...initialState,
      };
  }
}

function errorsReducer(state, newState) {
  return {
    ...state,
    ...newState,
  };
}

const ContactForm = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errorsState, setErrors] = useReducer(errorsReducer, initialErrors);
  const [formSent, setFormSent] = useState(false);
  const formRef = useRef(null);
  const captchaRef = useRef(null);
  const hitScrollbar =
    !window.matchMedia("(max-width: 768px) and (max-aspect-ratio: 15/9)")
      .matches &&
    window.matchMedia("(max-width: 1200px),(max-height: 640px)").matches;

  const onBlur = (e) => {
    const field = e.target.name.substr(5);
    const value = field === "acceptance" ? e.target.checked : e.target.value;

    dispatch({ field, value, type: "update" });
  };

  const submitForm = (e) => {
    e.preventDefault();
    const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    ["department", "name", "message"].forEach((el) => {
      errors[el] = !state[el] ? "This field is required" : "";
    });

    errors.email = !emailReg.test(state.email) ? "Invalid email" : "";
    errors.acceptance = !state.acceptance
      ? "You must accept the Personal Data Protection Policy"
      : "";

    if (
      window.grecaptcha &&
      window.grecaptcha.getResponse(captchaRef.current)
    ) {
      errors.recaptcha = "";
    } else {
      errors.recaptcha = "You must pass the anti-spam check";
    }

    setErrors(errors);

    if (Object.keys(errors).every((el) => errors[el] === "")) {
      const formData = new FormData();
      for (let key in state) {
        formData.append(key, state[key]);
      }

      fetch(process.env.MAIL_URL, {
        method: "POST",
        body: formData,
      })
        .then((e) => {
          if (e.url === process.env.MAIL_URL && e.ok) {
            dispatch({ type: "reset" });
            // window.alert("Your message was sent successfuly!");
            setFormSent(true);
            setTimeout(() => {
              formRef.current.reset();
            }, 1000);
            setTimeout(() => {
              setFormSent(false);
            }, 5000);
          } else {
            window.alert(
              "Your message cannot be sent at this time. Internal server error 500."
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (typeof window === "undefined" || !window.recaptcha) {
      return;
    }
    const captchaDiv = document.querySelector("#g-recaptcha-contact-form");

    if (!captchaDiv.childElementCount) {
      captchaRef.current = window.grecaptcha.render(
        "g-recaptcha-contact-form",
        {
          sitekey: process.env.G_CAPTCHA_SITE_KEY,
        }
      );
    }

    // return () =>
    //   captchaDiv.childElementCount &&
    //   captchaDiv.removeChild(captchaDiv.firstChild);
  }, [window.recaptcha]); // eslint-disable-line react-hooks/exhaustive-deps
  // ^ I need to retrigger on recaptcha load

  return (
    <FormWrap className="hide-on-expanded form-wrap">
      <ConditionalScrollbar
        condition={hitScrollbar}
        wrapper={(children) => (
          <Scrollbar wrapper={false}>{children}</Scrollbar>
        )}
      >
        <FormContent className="form-content">
          <h2 css={cfTitle}>Contact Form</h2>
          <form ref={formRef} noValidate>
            <div css={[formField, formFieldBorder]}>
              <FormLabel htmlFor="form-department">
                <LabelBox />
                <FormLabelName>Department*</FormLabelName>
              </FormLabel>
              <select
                css={[formElement, formInput, formSelect]}
                id="form-department"
                name="form-department"
                defaultValue=""
                onBlur={onBlur}
              >
                <option value="" disabled>
                  Select Department
                </option>
                <option value="general">General</option>
                <option value="commercial">Commercial</option>
                <option value="marketing">Marketing</option>
                <option value="purchasing">Purchasing</option>
                <option value="logistics">Logistics</option>
                <option value="hr">HR</option>
                <option value="infoingredients">List of Ingredients</option>
              </select>
              <DropDown />
              <ErrorField>{errorsState.department}</ErrorField>
            </div>
            <div css={[formField, formFieldBorder]}>
              <FormLabel htmlFor="form-name">
                <LabelBox />
                <FormLabelName>Name*</FormLabelName>
              </FormLabel>
              <input
                css={[formElement, formInput]}
                type="text"
                id="form-name"
                name="form-name"
                aria-label="Name"
                onBlur={onBlur}
              />
              <ErrorField>{errorsState.name}</ErrorField>
            </div>
            <div css={[formField, formFieldBorder]}>
              <FormLabel htmlFor="form-email">
                <LabelBox />
                <FormLabelName>Email*</FormLabelName>
              </FormLabel>
              <input
                css={[formElement, formInput]}
                type="email"
                id="form-email"
                name="form-email"
                aria-label="Email"
                onBlur={onBlur}
              />
              <ErrorField>{errorsState.email}</ErrorField>
            </div>
            <div css={[formField, formFieldBorder, { height: "auto" }]}>
              <FormLabel css={{ position: "absolute" }} htmlFor="form-message">
                <LabelBox />
                <FormLabelName>Message*</FormLabelName>
              </FormLabel>
              <textarea
                css={[formElement, formTextarea]}
                id="form-message"
                name="form-message"
                aria-label="Message"
                onBlur={onBlur}
              />
              <ErrorField css={{ paddingLeft: "5px" }}>
                {errorsState.message}
              </ErrorField>
            </div>
            <div
              css={[formField, { display: "flex", height: "auto" }]}
              className="mob-flexrow"
            >
              <input
                type="checkbox"
                id="form-acceptance"
                name="form-acceptance"
                aria-label="Acceptance"
                onBlur={onBlur}
                css={[{ width: "20px", height: "20px" }]}
              />
              <label htmlFor="form-acceptance" css={checkboxLabel}>
                I confirm that I am familiar with and accept the{" "}
                <Link
                  state={{ modal: true }}
                  to="/personal-data-protection-policy"
                >
                  Personal Data Protection Policy.
                </Link>
              </label>
              <ErrorField css={{ paddingLeft: "25px" }}>
                {errorsState.acceptance}
              </ErrorField>
            </div>
            <div
              css={[
                formField,
                {
                  display: "flex",
                  justifyContent: "space-between",
                  height: "auto",
                },
              ]}
              className="mob-flexrow"
            >
              <div
                className="g-recaptcha"
                data-sitekey={process.env.G_CAPTCHA_SITE_KEY}
                id="g-recaptcha-contact-form"
              ></div>
              <ErrorField css={{ paddingLeft: "5px" }}>
                {errorsState.recaptcha}
              </ErrorField>
              {!hitScrollbar && (
                <Media query="(max-width: 550px)">
                  {(matches) =>
                    matches ? (
                      <button css={buttonSubmit} onClick={submitForm}>
                        <span>Submit</span>
                      </button>
                    ) : (
                      <button css={buttonSubmit} onClick={submitForm}>
                        <SubmitButton />
                      </button>
                    )
                  }
                </Media>
              )}
            </div>
            <div css={[formField, lastField]}>
              <p css={{ margin: 0 }}>* Mandatory field.</p>
              {hitScrollbar && (
                <Media query="(max-width: 550px)">
                  {(matches) =>
                    matches ? (
                      <button css={buttonSubmit} onClick={submitForm}>
                        <span>Submit</span>
                      </button>
                    ) : (
                      <button css={buttonSubmit} onClick={submitForm}>
                        <SubmitButton />
                      </button>
                    )
                  }
                </Media>
              )}
            </div>
          </form>
          <div
            className={classnames({ "sent-successfuly": formSent })}
            css={messageSent}
          >
            <div css={messageSentContainer}>
              <p>Thank you for your message! We will reply soon.</p>
            </div>
          </div>
        </FormContent>
      </ConditionalScrollbar>
    </FormWrap>
  );
};
export default ContactForm;